<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <JobAssignmentOrderForm
      v-if="!isLoading"
      :jobAssignmentOrderData="jobAssignmentOrder"
      :submitName="$t('edit')"
      v-on:addOrUpdateJobAssignmentOrder="addOrUpdateJobAssignmentOrder()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import JobAssignmentOrderForm from "@/components/employees/jobAssignmentOrders/JobAssignmentOrderForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import JobAssignmentOrder from "@/models/employees/jobAssignmentOrders/JobAssignmentOrder";

export default {
  name: "JobAssignmentOrderEdit",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    JobAssignmentOrderForm,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      jobAssignmentOrder: new JobAssignmentOrder(),
    };
  },
  props: ["jobAssignmentOrderToken"],
  methods: {
    async getJobAssignmentOrderDetails() {
      this.isLoading = true;
      this.jobAssignmentOrder.jobAssignmentOrderToken =
        this.jobAssignmentOrderToken;
      try {
        let response =
          await this.jobAssignmentOrder.getJobAssignmentOrderDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.jobAssignmentOrder.fillData(
            response.data.jobAssignmentOrdersData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "JobAssignmentOrders" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "JobAssignmentOrders" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateJobAssignmentOrder() {
      this.isLoading = true;

      try {
        let response =
          await this.jobAssignmentOrder.addOrUpdateJobAssignmentOrder(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "JobAssignmentOrders" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getJobAssignmentOrderDetails();
  },
};
</script>

<style scoped lang="scss"></style>
